import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import "../css/style.css"

export default function Litro() {
  const data = useStaticQuery(graphql`
    query Litros {
      gcms {
        litroses {
          carta {
            url(transformation: { document: { output: { format: jpg } } })
          }
        }
      }
    }
  `)

  let litros = data.gcms.litroses

  return (
    <section>
      <div className="drink-area-alimentos">
        <div>
          {litros.map(litros => (
            <img
              className="drink-area-alimentos-2"
              alt=""
              src={litros.carta.url}
            />
          ))}
        </div>
      </div>
    </section>
  )
}
